import * as jose from "jose";
import { jwtVerify, importJWK, JWK } from "jose";
import { OpenIdService } from "services/requests";

const REACT_APP_AUTHORITY_URL: string = process.env.REACT_APP_AUTHORITY_URL ?? '';
const REACT_APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? '';

export const getAuthToken = () => {
  const user_datas_key = `oidc:useruser:${REACT_APP_AUTHORITY_URL}:${REACT_APP_CLIENT_ID}`;
  const user_datas = JSON.parse(window.localStorage.getItem(user_datas_key) || '{}');
  const token = user_datas?.access_token;
  return token
};

export async function verifyToken(token: string): Promise<boolean> {
  const jwks_datas = await getJWKS();
  const rsaKeyPublic = JSON.parse(JSON.stringify(jwks_datas)).keys[0]; //TODO use kid Header

  const rsaKeyPublicToBase64Url: JWK = {
    ...rsaKeyPublic,
    n: toBase64Url(rsaKeyPublic.n),
  };

  const key = await importJWK(rsaKeyPublicToBase64Url, "RS256");

  try {
    const { payload } = await jwtVerify(token, key);
    console.log("Token is valid:", payload);
    return true;
  } catch (err) {
    console.error("Token verification failed:", err);
  }
  return false;
}

function toBase64Url(base64: string): string {
  return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}

async function getJWKS() {
  const cachedData = localStorage.getItem("jwks_datas");
  if (cachedData) {
    const { data, expiresAt } = JSON.parse(cachedData);
    if (Date.now() < expiresAt) {
      return data;
    }
  }

  const jwks_datas = await OpenIdService.getOpenIdjwks();
  localStorage.setItem(
    "jwks_datas",
    JSON.stringify({ data: jwks_datas, expiresAt: Date.now() + 86400000 }) // 24 horas
  );
  return jwks_datas;
}


export async function createJWTMock(mySuperKey: jose.JWK): Promise<string> {
  let jwt = "";
  try {
    const privateKey = await jose.importJWK(mySuperKey, "RS256");

    const payload = {
      iss: "your-issuer",
      sub: "your-subject",
      aud: "your-audience",
      exp: Math.floor(Date.now() / 1000) + 60 * 60, // 1 hour expiration
      iat: Math.floor(Date.now() / 1000),
      customClaim: "customValue",
    };

    jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "RS256" })
      .sign(privateKey);
  } catch (error) {
    console.error("Erro:", error);
  }
  console.log(jwt);
  return jwt;
}
