import { LinkItems } from "layout/app/side-menu";
import {
    IconBriefcase,
    IconDashboard,
    IconHome,
    IconSettings,
    IconCards,
    IconEdit,
    IconFileSpreadsheet,
    IconGrid3x3,
    IconHandClick,
    IconLoader,
    IconPalette,
    IconStairs,
    IconTypography,
    IconBusinessplan,
    IconHistory,
    IconEqual,
  } from "@tabler/icons-react";
  import { DefaultIcon } from "components/basic/icon-default";

export const mainAppLinkItems: LinkItems = {
    application: {
      label: "Application",
      linkItems: {
        agencies: {
          label: "Agencies",
          linkTo: "/app/agencies",
          icon: <IconBriefcase stroke={1.5} />,
        },
        settings: {
          label: "Settings",
          linkTo: "/app/settings",
          icon: <IconSettings stroke={1.5} />,
        },
      },
    },
    documentation: {
      label: "Documentation",
      linkTo: "/app/documentation",
      linkItems: {
        palette: {
          label: "Palette",
          linkTo: "/app/documentation#palette",
          icon: <IconPalette stroke={1.5} />,
        },
        typography: {
          label: "Typography",
          linkTo: "/app/documentation#typography",
          icon: <IconTypography stroke={1.5} />,
        },
        buttons: {
          label: "Buttons",
          linkTo: "/app/documentation#buttons",
          icon: <IconHandClick stroke={1.5} />,
        },
        forms: {
          label: "Forms",
          linkTo: "/app/documentation#forms",
          icon: <IconFileSpreadsheet stroke={1.5} />,
        },
        cards: {
          label: "Cards",
          linkTo: "/app/documentation#cards",
          icon: <IconCards stroke={1.5} />,
        },
        dataGrid: {
          label: "DataGrid",
          linkTo: "/app/documentation#data-grid",
          icon: <IconGrid3x3 stroke={1.5} />,
        },
        stepsController: {
          label: "Steps Controller",
          linkTo: "/app/documentation#steps-controller",
          icon: <IconStairs stroke={1.5} />,
        },
      },
    },
    authenticated: {
      label: "Authenticated",
      linkItems: {
        dashboard: {
          label: "Dashboard",
          linkTo: "/app/dashboard",
          icon: <IconDashboard stroke={1.5} />,
        },
        realStates: {
          label: "Real States",
          linkTo: "/app/real-states",
          icon: <IconBriefcase stroke={1.5} />,
        },
        properties: {
          label: "Properties",
          linkTo: "/app/real-estate/properties",
          icon: <IconHome stroke={1.5} />,
        },
        settings: {
          label: "Settings",
          linkTo: "/app/real-estate/settings",
          icon: <IconSettings stroke={1.5} />,
        },
        crud: {
          label: "CRUD",
          linkTo: "/app/real-estate/properties",
          icon: <IconEdit stroke={1.5} />,
        },
      },
    },
    unauthenticated: {
      label: "Unauthenticated",
      linkItems: {
        landing: {
          label: "Landing",
          linkTo: "/app/",
          icon: <IconHome stroke={1.5} />,
        },
        register: {
          label: "Register",
          linkTo: "/app/register",
          icon: <IconEdit stroke={1.5} />,
        },
      },
    },
    sideMenuExample: {
      label: "Side Menu Example",
      linkItems: {
        subpage1: {
          label: "Subpage 1",
          linkTo: "",
        },
        subpage2: {
          label: "Subpage 2",
          linkTo: "",
        },
        subpage3: {
          label: "Subpage 3",
          linkTo: "",
        },
      },
    },
    others: {
      label: "Outros",
      linkItems: {
        loading: {
          label: "Loading",
          linkTo: "/app/loading",
          icon: <IconLoader stroke={1.5} />,
        },
        tests: {
          label: "Tests",
          linkTo: "/app/test",
          icon: <IconLoader stroke={1.5} />,
        },
        zustandTest: {
          label: "Zustand Test",
          linkTo: "/app/test-zustand",
          icon: <IconLoader stroke={1.5} />,
        },
      },
    },
  };
  
  export const agencyLinkItems = (agency: Record<string, any>): LinkItems => ({
    agencyLinks: {
      linkItems: {
        agency: {
          label: agency.name || "",
          linkTo: `agencies/${agency.id}`,
          icon: <DefaultIcon text={agency.name} fontSize={24} />,
        },
        dashboard: {
          label: "Painel",
          linkTo: `agencies/${agency.id}/dashboard`,
          icon: <IconDashboard stroke={1.5} />,
        },
        properties: {
          label: "Propriedades",
          linkTo: `agencies/${agency.id}/properties`,
          icon: <IconHome stroke={1.5} />,
        },
        settings: {
          label: "Configurações",
          linkTo: "`agencies/${agency.id}/settings`",
          icon: <IconSettings stroke={1.5} />,
        },
      },
    },
  });
  
  export const propertyLinkItems = (
    property: Record<string, any>
  ): LinkItems => ({
    propertyLinks: {
      linkItems: {
        property: {
          label: property.property_title || "",
          linkTo: `agencies/${property.agency_id}/properties/${property.id}`,
          icon: <DefaultIcon text={property.property_title} fontSize={24} />,
        },
        business: {
          label: "Negociações",
          linkTo: `agencies/${property.agency_id}/properties/${property.id}/business`,
          icon: <IconBusinessplan stroke={1.5} />,
        },
        history: {
          label: "Histórico",
          linkTo: `agencies/${property.agency_id}/properties/${property.id}/history`,
          icon: <IconHistory stroke={1.5} />,
        },
        similar: {
          label: "Similares",
          linkTo: `agencies/${property.agency_id}/properties/${property.id}/similar`,
          icon: <IconEqual stroke={1.5} />,
        },
        // edit: {
        //   label: "Editar",
        //   linkTo: `agencies/${property.agency_id}/properties/${property.id}/edit`,
        //   icon: <IconEdit stroke={1.5} />,
        // },
      },
    },
  });