import Button from "components/basic/button";
import { FlexColumn, FlexRow } from "components/basic/flex";

import CustomRadio from "components/form/primitives/custom-radio";
import { useStepsContext } from "components/steps-controller/steps-context";
import {
  IconBuilding,
  IconBuildingCommunity,
  IconHome,
  IconStar,
} from "@tabler/icons-react";
import FormSubmitBehavior from "components/form/form-submit";
import PriceCard, { Feature } from "pages/unauthenticated/landing/pricing/price-card";

const attributes = ["Aluguel e venda", "Até 100 imóveis", "Até 3 usuários"];
const discounts = {
  monthly: 0,
  quarterly: 5,
  annual: 15,
};
const paymentPeriod = "monthly";

const plans = [
  {
    key: "basic",
    value: "basic",
    icon: IconHome,
    title: "BÁSICO",
    price: 115,
    discount: discounts[paymentPeriod],
    features: [
      { label: "Usuários", quantity: 2 },
      { label: "Imóveis", quantity: 150 },
      { label: "Contratos", quantity: "infinite" },
      { label: "Gestão de locação", check: true },
      { label: "Gestão de venda", check: true },
      { label: "Preço por usuário adicional", value: 80 },
      { label: "Preço por imóvel adicional", value: 1.5 },
    ] as Feature[],
  },
  {
    key: "explorer",
    value: "explorer",
    icon: IconBuilding,
    title: "DESBRAVADOR",
    price: 235,
    discount: discounts[paymentPeriod],
    features: [
      { label: "Usuários", quantity: 5 },
      { label: "Imóveis", quantity: 500 },
      { label: "Contratos", quantity: "infinite" },
      { label: "Gestão de locação", check: true },
      { label: "Gestão de venda", check: true },
      { label: "Preço por usuário adicional", value: 40 },
      { label: "Preço por imóvel adicional", value: 0.4 },
    ] as Feature[],
  },
  {
    key: "professional",
    value: "professional",
    icon: IconBuildingCommunity,
    title: "PROFISSIONAL",
    price: 350,
    discount: discounts[paymentPeriod],
    features: [
      { label: "Usuários", quantity: 10 },
      { label: "Imóveis", quantity: 2000 },
      { label: "Contratos", quantity: "infinite" },
      { label: "Gestão de locação", check: true },
      { label: "Gestão de venda", check: true },
      { label: "Preço por usuário adicional", value: 30 },
      { label: "Preço por imóvel adicional", value: 0.15 },
    ] as Feature[],
  },
  {
    key: "premium",
    value: "premium",
    icon: IconStar,
    title: "PREMIUM",
    price: 470,
    discount: discounts[paymentPeriod],
    features: [
      { label: "Usuários", quantity: 20 },
      { label: "Imóveis", quantity: 5000 },
      { label: "Contratos", quantity: "infinite" },
      { label: "Gestão de locação", check: true },
      { label: "Gestão de venda", check: true },
      { label: "Preço por usuário adicional", value: 20 },
      { label: "Preço por imóvel adicional", value: 0.08 },
    ] as Feature[],
  },
];

type PlansProps = {
  previousStepId: string;
  nextStepId: string;
};
const RegisterPlans: React.FC<PlansProps> = ({
  previousStepId,
  nextStepId,
}) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;
  return (
    <FlexColumn sx={{ gap: 2, width: "100%" }}>
      <FlexRow
        sx={{
          justifyContent: "space-evenly",
          gap: 5,
          alignItems: "center",
          width: "100%",
        }}
      >
        {plans.map((plan) => (
          <CustomRadio key={plan.key} name="plan" value={plan.value}>
            {({ selected }) => (
              <PriceCard
                href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=${plan.value}`}
                icon={plan.icon}
                title={plan.title}
                price={plan.price}
                discount={plan.discount}
                features={plan.features}
                backgroundVariant={plans.indexOf(plan) + 1}
                selected={selected}
              />
            )}
          </CustomRadio>
        ))}
      </FlexRow>
      <FlexRow sx={{ justifyContent: "center", gap: 2, width: "100%" }}>
        <FormSubmitBehavior onSubmit={() => setCurrentStepId(nextStepId)}>
          <Button sx={{ width: "100%" }}>Avançar</Button>
        </FormSubmitBehavior>
      </FlexRow>
    </FlexColumn>
  );
};

export default RegisterPlans;
