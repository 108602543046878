import { alpha, shade, tint } from "@theme-ui/color";
import { Theme } from "theme-ui";

//const primary = generateShades("#00c2b8");
const primary = generateShades("#13a79f");
const secondary = generateShades("#0099dd");
const success = primary;
const info = secondary;
const warning = generateShades("#FFB020");
const error = generateShades("#D14343");

const red = generateShades("#D14343");
const pink = generateShades("#c548a7");
const purple = generateShades("#6743b1");
const blue = generateShades("#1e88e5");
const green = generateShades("#65ac54");
const yellow = generateShades("#e0b540");
const orange = generateShades("#cd7834");

const otherColors = {
  themeRed: red,
  themePink: pink,
  themePurple: purple,
  themeBlue: blue,
  themeGreen: green,
  themeYellow: yellow,
  themeOrange: orange,
} satisfies Theme["colors"];

const neutralMain = "#607d8b";
const neutral = {
  n1: tint(neutralMain, 0.95)({}),
  n2: tint(neutralMain, 0.8)({}),
  n3: tint(neutralMain, 0.6)({}),
  n4: tint(neutralMain, 0.4)({}),
  n5: neutralMain,
  n6: shade(neutralMain, 0.3)({}),
  n7: shade(neutralMain, 0.5)({}),
  n8: shade(neutralMain, 0.7)({}),
  n9: shade(neutralMain, 0.9)({}),
} satisfies Theme["colors"];

//const neutral = generateShades(neutralMain);

const darken = {
  n1: alpha("#000000", 0.1)({}),
  n2: alpha("#000000", 0.2)({}),
  n3: alpha("#000000", 0.3)({}),
  n4: alpha("#000000", 0.4)({}),
  n5: alpha("#000000", 0.5)({}),
  n6: alpha("#000000", 0.6)({}),
  n7: alpha("#000000", 0.7)({}),
  n8: alpha("#000000", 0.8)({}),
  n9: alpha("#000000", 0.9)({}),
} satisfies Theme["colors"];

const lighten = {
  n1: alpha("#FFFFFF", 0.1)({}),
  n2: alpha("#FFFFFF", 0.2)({}),
  n3: alpha("#FFFFFF", 0.3)({}),
  n4: alpha("#FFFFFF", 0.4)({}),
  n5: alpha("#FFFFFF", 0.5)({}),
  n6: alpha("#FFFFFF", 0.6)({}),
  n7: alpha("#FFFFFF", 0.7)({}),
  n8: alpha("#FFFFFF", 0.8)({}),
  n9: alpha("#FFFFFF", 0.9)({}),
} satisfies Theme["colors"];

const background = {
  default: "#e1e6eb",
  paper: "#FFFFFF",
  dark: "#0d1728",
} satisfies Theme["colors"];

const text = {
  main: "#363d4f",
  dark: "#1c212a",
  faded: "#adadad",
  disabled: "rgba(55, 65, 81, 0.48)",
  link: "#3874ff",
  linkHover: "#003cc7",
} satisfies Theme["colors"];

const gradients = {
  light2: `linear-gradient(141deg, ${primary.light2} 0%, ${secondary.light2} 100%)`,
  light1: `linear-gradient(141deg, ${primary.light1} 0%, ${secondary.light1} 100%)`,
  main: `linear-gradient(141deg, ${primary.main} 0%, ${secondary.main} 100%)`,
  dark1: `linear-gradient(141deg, ${primary.dark1} 0%, ${secondary.dark1} 100%)`,
  dark2: `linear-gradient(141deg, ${primary.dark2} 0%, ${secondary.dark2} 100%)`,
  darkBackground:
    "linear-gradient(141deg, rgba(13,23,40,1) 0%, rgba(17,20,26,1) 100%)",
} satisfies Theme["colors"];

const action = {
  active: "#6B7280",
  focus: "rgba(55, 65, 81, 0.12)",
  hover: "rgba(55, 65, 81, 0.04)",
  selected: "rgba(55, 65, 81, 0.08)",
  disabledBackground: "rgba(55, 65, 81, 0.12)",
  disabled: "rgba(55, 65, 81, 0.26)",
} satisfies Theme["colors"];

export const colors = {
  primary,
  secondary,
  success,
  info,
  warning,
  error,
  neutral,
  darken,
  lighten,
  background,
  text,
  gradients,
  ...otherColors,
} satisfies Theme["colors"];

function generateShades(mainColor: string) {
  return {
    light3: tint(mainColor, 0.8)({}),
    light2: tint(mainColor, 0.5)({}),
    light1: tint(mainColor, 0.3)({}),
    main: mainColor,
    dark1: shade(mainColor, 0.3)({}),
    dark2: shade(mainColor, 0.5)({}),
    dark3: shade(mainColor, 0.8)({}),
    contrastText: "#FFFFFF",
  } satisfies Theme["colors"];
}
