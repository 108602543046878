import Base from "components/basic/base";
import { FormEvent } from "react";
import {
  FieldState,
  FormContext as FormContextType,
  OnFormChange,
  FieldError,
  FieldValidation,
  FieldData,
  FormSetters,
  FormStates,
  FormValues,
  HandleChangeValue,
  HandleChangeError,
  HandleChangeState,
  HandleChangeStore,
  HandleChangeValues,
} from "./form-store.d";
import { useShallow } from "zustand/react/shallow";
import { produce } from "immer";
import createLocalZustand from "context/local-zustand";

const createFormStore = createLocalZustand<FormContextType>(
  (initializer) => (set, get) => ({
    formValues: { ...initializer.formValues },
    formStates: { ...initializer.formStates },
    formErrors: {},
    formValidations: {},
    required: initializer.required ?? [],

    set: set,
    get: get,

    updateValues: (newValues: Record<string, string>) =>
      set((state) => {
        const { formValues } = get();
        console.log(formValues);
        console.log(newValues);
        const newState = produce(state, (draft) => {
          draft.formValues = { ...formValues, ...newValues };
        });
        initializer?._onChangeValues?.(newState);
        return newState;
      }),

    setFieldValues: (values, override = false) =>
      set((state) => {
        const newState = produce(state, (draft) => {
          if (!override) Object.assign(draft.formValues, values);
          else draft.formValues = values;
        });
        initializer?._onChangeValues?.(newState);
        return newState;
      }),

    setFieldValue: (fieldName, value) =>
      set((state) => {
        const newState = produce(state, (draft) => {
          draft.formValues[fieldName] = value;
        });
        initializer?._onChangeValues?.(newState);
        return newState;
      }),

    setFieldState: (fieldName, value) =>
      set((state) =>
        produce(state, (draft) => {
          draft.formStates[fieldName] = value;
        })
      ),

    setFieldError: (fieldName, error) =>
      set((state) =>
        produce(state, (draft) => {
          draft.formErrors[fieldName] = error;
        })
      ),

    validate: () => {
      const { formValues, required, setFieldError } = get();
      let valid = true;
      // Validate requireds
      for (let field of required) {
        const value = formValues[field];
        if (!value || value === "" || value === false) {
          setFieldError(field, "Campo obrigatório");
          valid = false;
        } else {valid = true
          setFieldError(field, undefined);
        };
      }

      const { formErrors } = get();

      // Validate errors
      if (Object.values(formErrors).some((field) => field !== undefined))
        valid = false;
      console.log(valid);
      return valid;
    },

    clearForm: () =>
      set((state) => ({
        formValues: {},
        formStates: {},
        formErrors: {},
      })),
  })
);

const { Provider } = createFormStore;
export const { useStore: useFormStore } = createFormStore;

type FormProviderProps = {
  initialValues?: FormValues;
  initialStates?: FormStates;
  onChangeValues?: OnFormChange;
  required?: string[];
};
const FormProvider: ComponentWithChildren<FormProviderProps> = ({
  initialValues,
  initialStates,
  onChangeValues,
  required,
  onSubmit,
  children,
}) => {
  return (
    <Base
      as="form"
      sx={{ display: "contents" }}
      onSubmit={(e: FormEvent<HTMLInputElement>) => e.preventDefault()}
    >
      <Provider
        initialState={{
          formValues: initialValues,
          formStates: initialStates,
          _onChangeValues: onChangeValues,
          _onSubmit: onSubmit,
          required,
        }}
      >
        {children}
      </Provider>
    </Base>
  );
};

export const useFormStoreFieldData = (fieldName: string): FieldData => {
  return useFormStore(
    useShallow((state) => ({
      value: state.formValues[fieldName],
      error: state.formErrors[fieldName],
      state: state.formStates[fieldName],
      validation: state.formValidations[fieldName],
    }))
  ) satisfies FieldData;
};

export const useFormStoreFieldValue = (fieldName: string) => {
  return useFormStore((state) => state.formValues[fieldName]);
};

export const useFormStoreFieldError = (fieldName: string): FieldError => {
  return useFormStore(
    (state) => state.formErrors[fieldName]
  ) satisfies FieldError;
};

export const useFormStoreFieldState = (fieldName: string): FieldState => {
  return (useFormStore((state) => state.formStates[fieldName]) ??
    {}) satisfies FieldState;
};

export const useFormStoreFieldValidation = (
  fieldName: string
): FieldValidation => {
  return useFormStore(
    (state) => state.formValidations[fieldName]
  ) satisfies FieldValidation;
};

export const useFormStoreSetters = (): FormSetters => {
  return useFormStore(
    useShallow((state) => ({
      set: state.set,
      updateValues: state.updateValues,
      setFieldValues: state.setFieldValues,
      setFieldValue: state.setFieldValue,
      setFieldState: state.setFieldState,
      setFieldError: state.setFieldError,
    }))
  ) as {
    set: HandleChangeStore;
    updateValues: HandleChangeValues;
    setFieldValues: HandleChangeValues;
    setFieldValue: HandleChangeValue;
    setFieldState: HandleChangeState;
    setFieldError: HandleChangeError;
  };
};

export const FormConsumer: ComponentFn<typeof useFormStore> = ({
  children,
}) => {
  return children(useFormStore);
};

export default FormProvider;
