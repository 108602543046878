import { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import {
  RealStateCard,
  RealStates,
} from "../manage-agencies/manage-agencies-cards";
import { agencyContext, MemberType } from "./agencies-context";
import { CardSection } from "components/basic/card/card-section";
import { withAuthenticationRequired } from "react-oidc-context";
import { DefaultIcon } from "components/basic/icon-default";
import { appLayoutContext } from "layout/app/layout-context";
import { useEffect, useState } from "react";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import { useLoading } from "context/loading-context";
import IconButton from "components/basic/icon-button";
import { IconUserPlus } from "@tabler/icons-react";
import Icon from "components/basic/icon";
import { BiUserPlus } from "react-icons/bi";
import AddUserPopup from "components/composed/add-user-popup";
import Tooltip from "components/basic/tooltip";

const OverviewPage = () => {
  const { useStore } = appLayoutContext;
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  const { useStore: useAgencyStore } = agencyContext;
  const agency = useAgencyStore((state) => state.agency);
  const agency_first_letter = agency.name ? agency.name[0] : "";

  const members = agency.members ?? [];

  const [isAddUserVisible, setIsAddUserVisible] = useState(false);

  useEffect(() => {
    // const overviewPath = `/app/agencies/${agency_id}`;
    // const overview_navigate = (
    //   <SideMenuLink label={""} linkTo={overviewPath} />
    // );
    updateNavigationBar(null, 1);
  }, []);

  return (
    <>
      <FlexColumn>
        <Text variant="h3">Overview</Text>
        <BreadCrumb />
      </FlexColumn>

      <FlexRow sx={{ gap: 2, flexWrap: "wrap" }}>
        <CardWithSections size="micro" sx={{ flexGrow: 3, minWidth: "400px" }}>
          <CardHeader>
            <FlexRow sx={{ gap: 1 }}>
              <DefaultIcon text={agency.name} />
              <Text
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                {agency.name}
              </Text>
            </FlexRow>
          </CardHeader>

          <CardSection>
            <FlexColumn sx={{ gap: 1 }}>
              <FlexRow>
                <Text variant="h6" sx={{ marginBlock: "auto" }}>
                  ID:
                </Text>
                <Text sx={{ px: 1 }}>{agency.id}</Text>
              </FlexRow>

              <FlexRow>
                <Text variant="h6" sx={{ marginBlock: "auto" }}>
                  Status:
                </Text>
                <Text sx={{ px: 1 }}>Ativo</Text>
                {/* TODO trocar para agency.status */}
              </FlexRow>

              <Text variant="h6">Descrição</Text>
              <Text sx={{ px: 2 }}>{agency.description}</Text>
            </FlexColumn>
          </CardSection>
        </CardWithSections>

        <CardWithSections size="micro" sx={{ flexGrow: 1, maxWidth: "400px" }}>
          <CardHeader>
            <FlexRow sx={{ gap: 1, alignItems: "center" }}>
              <Text
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                Members
              </Text>
              {members.length}
              <FlexFill />

              <IconButton
                variant="soft"
                onClick={() => setIsAddUserVisible(true)}
              >
                <Icon as={BiUserPlus} sx={{ fontSize: 5 }} />
              </IconButton>
            </FlexRow>
          </CardHeader>

          <CardSection sx={{ gap: 1 }}>
            {members.map((member: MemberType) => (
              <Tooltip key={member.id} label={member.email} >
              <FlexRow sx={{ gap: 1 }}>
                <DefaultIcon text={member.first_name} fontSize={24} />
                {member.first_name + " " + member.last_name}
              </FlexRow>
              </Tooltip>
            ))}
          </CardSection>
        </CardWithSections>
      </FlexRow>

      <AddUserPopup
        visible={isAddUserVisible}
        setVisible={setIsAddUserVisible}
      />
    </>
  );
};

export default withAuthenticationRequired(OverviewPage);
