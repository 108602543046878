import { FlexRow } from "components/basic/flex";
import Header from "layout/app/header";
import SideMenu, { LinkItem } from "layout/app/side-menu";
import PageContent from "layout/app/page-content";
import { useEffect, useState } from "react";
import Base from "components/basic/base";

import { withAuthenticationRequired } from "react-oidc-context";
import AppPopup from "./app-popup";
import { appLayoutContext, useLayout } from "./layout-context";
import { LoadingPage } from "pages/other/loading-page";
import { HEADER_HEIGHT } from "./side-menu/layout-constants";

const { Provider: ContextProvider } = appLayoutContext;

const AppLayout: ComponentWithChildren = ({ children, initialState }) => {
  const [menuVisibility, setMenuVisibility] = useState(true);

  return (
    <ContextProvider initialState={initialState}>
      <Base sx={{ width: "100%", backgroundColor: "background.default" }}>
        <Header {...{ setMenuVisibility }} />
        <FlexRow
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "stretch",
            overflowX: "clip",
          }}
        >
          <SideMenu {...{ menuVisibility, setMenuVisibility }} />
          <PageView>{children}</PageView>
        </FlexRow>
        <AppPopup />
      </Base>
    </ContextProvider>
  );
};

export const PageView: ComponentWithChildren = ({ children }) => {
  const { isLoading } = useLayout((state) => state);

  return (
    <>
      <PageContent sx={
        isLoading
          ? {
              position: "relative",
              width: "100%",
              height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            }
          : {}
      }>{children}{isLoading && (
        <Base
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.default",
            zIndex: 50,
            pointerEvents: "none",
          }}
        >
          <LoadingPage />
        </Base>
      )}</PageContent>
      
    </>
  );
};

export default withAuthenticationRequired(AppLayout);
