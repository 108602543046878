import Base from "components/basic/base";
import createLocalZustand from "context/local-zustand";
import { produce } from "immer";
import { LoadingPage } from "pages/other/loading-page";

interface LoadingContextType {
  loadingQueue: number;
  set: HandleChangeStore;
  get: () => LoadingContextType;
  addLoading: () => void;
  removeLoading: () => void;
  isLoading: boolean;
}

export type HandleChangeStore = (
  partial:
    | LoadingContextType
    | Partial<LoadingContextType>
    | ((
        state: LoadingContextType
      ) => LoadingContextType | Partial<LoadingContextType>),
  replace?: boolean | undefined
) => void;

export const loadingContext = createLocalZustand<LoadingContextType>(
  (initializer) => (set, get) => ({
    set: set,
    get: get,
    addLoading: () => {
      set(
        produce((state) => {
          state.loadingQueue += 1;
          state.isLoading = true;
        })
      );
    },
    removeLoading: () => {
      setTimeout(() => {
        set(
          produce((state) => {
            if (state.loadingQueue > 0) state.loadingQueue -= 1;
            if (state.loadingQueue === 0) state.isLoading = false;
          })
        );
      }, 200);
    },
    loadingQueue: 0,
    isLoading: false,
  })
);

export const { useStore: useLoading } = loadingContext;

const { Provider } = loadingContext;
export const LoadingProvider: ComponentWithChildren = ({ children }) => {
  return (
    <Provider>
      <LoadingView>{children}</LoadingView>
    </Provider>
  );
};

export const LoadingView: ComponentWithChildren = ({ children }) => {
  const { isLoading } = useLoading((state) => state);

  return (
    <Base
      sx={
        isLoading
          ? { position: "relative" }
          : { width: "100vw", height: "100vh" }
      }
    >
      {children}
      {isLoading && (
        <Base
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.default",
            zIndex: 50,
            pointerEvents: "none", 
          }}
        >
          <LoadingPage />
        </Base>
      )}
    </Base>
  );
};
