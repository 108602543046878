import { UserAuthManager } from "context/authentication";
import {
  decodeProtectedHeader,
  jwtVerify,
  JWK,
  JWSHeaderParameters,
  KeyLike,
  importJWK,
} from "jose";
import { User } from "oidc-client-ts";

export class MissingJWKSURLError extends Error {
  constructor(oidcProviderURL: string | null) {
    super(`OpenID Provider ${oidcProviderURL} has no a JWKS URL.`);
  }
}

export class UnreachableURIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnreachableURIError";
  }
}

export class UnreachableOpenIDProviderURIError extends UnreachableURIError {
  constructor(openIDProviderURL: string | null) {
    super(`OpenID provider ${openIDProviderURL} is unreachable.`);
    this.name = "UnreachableOpenIDProviderURIError";
  }
}

export class UnreachableJWKSURIError extends UnreachableURIError {
  constructor(jwksURI: string | null) {
    super(`JWKS URI ${jwksURI} is unreachable.`);
    this.name = "UnreachableJWKSURIError";
  }
}

export class EmptyJWKSError extends Error {
  constructor() {
    super("Unable to find a signing key that matches the provided token.");
    this.name = "EmptyJWKSError";
  }
}

export declare interface OIDCProviderSettings {
  /** The URL of the OIDC/OAuth2 provider */
  authority: string;
}

export default class OIDCService {
  private configuration: OIDCProviderSettings;
  private jwksURI: string | null;

  constructor(configuration: OIDCProviderSettings) {
    this.configuration = configuration;
    this.jwksURI = null;
  }

  public getJKWSURI(): string | null {
    return this.jwksURI;
  }

  protected async getOIDCConfiguration(): Promise<string> {
    if (!this.jwksURI) {
      const oidcConfigurationURL: string = `${this.configuration.authority}/.well-known/openid-configuration`;

      try {
        const response: Response = await fetch(oidcConfigurationURL);

        if (!response.ok)
          throw new UnreachableOpenIDProviderURIError(oidcConfigurationURL);

        const data = (await response.json()) as any;

        this.jwksURI = data.jwks_uri;
      } catch (error) {
        if (error instanceof RTCError) {
          throw new UnreachableOpenIDProviderURIError(oidcConfigurationURL);
        }

        throw error;
      }
    }

    if (!this.jwksURI)
      throw new MissingJWKSURLError(this.configuration.authority);

    return this.jwksURI;
  }

  protected async getSigningKeys(): Promise<JWK[]> {
    const jwksURI: string = await this.getOIDCConfiguration();

    try {
      const response: Response = await fetch(jwksURI);
      const data: any = await response.json();

      if (!response.ok) throw new UnreachableJWKSURIError(jwksURI);

      return data.keys;
    } catch (error) {
      if (error instanceof RTCError) {
        throw new UnreachableJWKSURIError(jwksURI);
      }

      throw error;
    }
  }

  protected async getSigningKey(header: JWSHeaderParameters): Promise<KeyLike> {
    const keys: JWK[] = await this.getSigningKeys();

    let signingKey: JWK | undefined;

    if (header.kid) signingKey = keys.find((key) => key.kid === header.kid);
    // FIXME kid should be a required header attribute of every token
    else if (keys.length > 0) signingKey = keys[0];

    if (!signingKey) throw new EmptyJWKSError();

    // Ensure TypeScript understands that this will be a KeyLike
    return importJWK(signingKey, "RS256") as Promise<KeyLike>;
  }

  public async validateToken(token: string): Promise<boolean> {
    let result: boolean = true;

    try {
      const key = await this.getSigningKey(decodeProtectedHeader(token));

      const localConfiguration = {
        issuer: this.configuration.authority,
      };

      await jwtVerify(token, key, localConfiguration);
    } catch (error) {
      if (error instanceof UnreachableURIError) {
        throw error;
      }

      result = false;
    }

    return result;
  }

  public async refreshToken(): Promise<User | null | undefined> {
    try {
      await UserAuthManager.revokeTokens();
      const user_datas = await UserAuthManager.getUser();

      return user_datas;
    } catch (tokenRenewalError) {
      console.error("Refresh token error", tokenRenewalError);
      localStorage.setItem("session_route", window.location.search);
      await UserAuthManager.removeUser();
      await UserAuthManager.signinRedirect();
    }
  }
}

const REACT_APP_AUTHORITY_URL: string = process.env.REACT_APP_AUTHORITY_URL ?? '';
export const oidcService = new OIDCService({authority: REACT_APP_AUTHORITY_URL});