import { CardWithSections } from "components/basic/card/card";
import { FlexCenter } from "components/basic/flex";
import StepsProvider from "components/steps-controller/steps-context";
import { earlyAdopterFormContext } from "./context";
import { Steps, steps } from "./steps";
import { Header } from "./header";
import { Content } from "./content";
import { withAuthenticationRequired } from "react-oidc-context";
import { useStepsContext } from "components/steps-controller/steps-context";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { EarlyAdopterService } from "services/requests";
import { globalErrorType, useGlobalError } from "context/error-context";
import { appLayoutContext } from "layout/app/layout-context";
import { useNavigate } from "react-router";
import { useLoading } from "context/loading-context";

const EarlyAdopterPage = () => {
  return (
    <FlexCenter sx={{ height: "100%" }}>
      <StepsProvider stepIdOrder={Object.keys(steps)}>
        <Body />
      </StepsProvider>
    </FlexCenter>
  );
};

function Body() {
  const navigate = useNavigate();
  const auth = useAuth();

  const { setGlobalError } = useGlobalError((state) => state);

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { addLoading, removeLoading } = useLoading((state) => state);

  useEffect(() => {
    addLoading();
    EarlyAdopterService.getEarlyAdopter()
      .then(() => setCurrentStepId("finalize"))
      .catch((e) => {
        const error = e as globalErrorType;
        if (error?.status != 404) setGlobalError(error, navigate)
      })
      .finally(() => removeLoading());
  }, []);

  const { Provider: ContextProvider } = earlyAdopterFormContext;
  return (
    <CardWithSections direction="vertical" sx={{ width: "600px" }}>
      <Header />
      <Steps />
      <ContextProvider>
        <Content />
      </ContextProvider>
    </CardWithSections>
  );
}

export default withAuthenticationRequired(EarlyAdopterPage);
