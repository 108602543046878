import { DataGridRowData } from "./data-grid-columns";
import { useDataGridStore } from "./data-grid-store";
import { getDataGridCellByType } from "./cell/cell";

type CellsProps = { rowData: DataGridRowData };
export const Cells: Component<CellsProps> = ({ rowData }) => {
  const columns = useDataGridStore((state) => state.columns);
  return (
    <>
      {Object.entries(columns).map(([columnId, columnData]) => {
        const CellComponent = getDataGridCellByType(columnData.renderAs);
        const cellData = rowData[columnId] as any;
        return (
          <CellComponent
            key={columnId}
            {...{ rowData, columnId, columnData, cellData }}
            sx={{
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          />
        );
      })}
    </>
  );
};

export default Cells;
