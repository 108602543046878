import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { ReactNode, useEffect, useState } from "react";
import { AuthProvider, useAuth as _useAuth } from "react-oidc-context";
import { getAuthToken, verifyToken } from "services/auth/auth";
import { oidcService } from "services/oidc/oidc";
import { fastContext } from "./fast-context";
import { useLoading } from "./loading-context";

export const {
  REACT_APP_AUTHORITY_URL = "",
  REACT_APP_CLIENT_ID = "",
  REACT_APP_REDIRECT_URI = "",
  REACT_APP_LINK_AUTHIEN = "",
} = process.env;

// OIDC configuration
const oidcConfiguration = {
  authority: REACT_APP_AUTHORITY_URL,
  client_id: REACT_APP_CLIENT_ID,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: "code",
  scope: "openid",
  userStore: new WebStorageStateStore({
    prefix: "oidc:user",
    store: window.localStorage,
  }),
  stateStore: new WebStorageStateStore({
    prefix: "oidc:state",
    store: window.localStorage,
  }),
  loadUserInfo: true,
  automaticSilentRenew: true,
};

export const UserAuthManager = new UserManager(oidcConfiguration);

export const validateAuthContext = fastContext;

export const AuthenticationProvider: ComponentWithChildren = ({ children }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const { addLoading, removeLoading } = useLoading((state) => state);

  const validateToken = async () => {
    const access_token = getAuthToken() ?? searchParams.get("code") ?? "";
    return await verifyToken(access_token);
  };

  const signUp = async () => {
    if (window.location.pathname.startsWith("/app")) {
      localStorage.setItem("returnPath", window.location.href);
    }
    await UserAuthManager.removeUser();
    await UserAuthManager.signinRedirect();
  };

  useEffect(() => {
    addLoading();
    validateToken()
      .then(async (result) => {
        if (!result) {
          if (window.location.pathname.startsWith("/app")) {
            localStorage.setItem("returnPath", window.location.href);
          }
          console.log(window.location.href);
          await UserAuthManager.removeUser();
        }
      })
      .catch(async (err) => {
        await signUp();
      })
      .finally(() => removeLoading());
  }, []);

  return <AuthProvider {...oidcConfiguration}>{children}</AuthProvider>;
};

export const useAuth = _useAuth;

export type ProtectedRouteProps = {
  element: ReactNode;
};

export default AuthenticationProvider;
