import { useStepsContext } from "components/steps-controller/steps-context";
import { useStoreSettersProperty } from "../context";
import DropzoneField from "components/form/fields/dropzone-field";
import Button from "components/basic/button";
import Base from "components/basic/base";
import FormSubmitBehavior from "components/form/form-submit";
import { fileToBase64 } from "utils/imagesManipulation";
import { globalErrorType, useGlobalError } from "context/error-context";
import { appLayoutContext } from "layout/app/layout-context";
import { useNavigate } from "react-router";

type ImagesProps = {
  nextStepId: string;
  previousStepId: string;
};

const ImagesForm: React.FC<ImagesProps> = ({ previousStepId, nextStepId }) => {
  const navigate = useNavigate();

  const { useStore: useLayoutContext } = appLayoutContext;
  const { updatePopupProps } = useLayoutContext((state) => state);

  const { setGlobalError } = useGlobalError((state) => state);

  const { updateValues: updateContextValues } = useStoreSettersProperty();
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  async function onSubmitForm(formValues: Record<string, any>) {
    const imagesData = formValues.images;

    if (imagesData && imagesData.length > 0) {
      try {
        const base64Images = await Promise.all(
          imagesData.map((image: File) => fileToBase64(image))
        );
        const imagesJsonString = JSON.stringify(base64Images);
        updateContextValues({ images: imagesJsonString });
      } catch (error) {
        setGlobalError(error as globalErrorType, navigate, updatePopupProps);
        
        console.error("Erro ao converter imagens para base64:", error);
      }
    }

    setCurrentStepId(nextStepId);
  }

  return (
    <>
      <Base
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}
      >
        <DropzoneField
          name="images"
          label="Carregar Imagens"
          idleMessage="Arraste suas imagens aqui ou Pressione para selecionar"
          dropMessage="Solte suas imagens aqui"
          rejectMessage="Formato de arquivo incorreto. Deve ser uma imagem"
        />
        <Base
          sx={{ width: "100%", display: "flex", justifyContent: "end", gap: 1 }}
        >
          <Button onClick={() => setCurrentStepId(previousStepId)}>
            Voltar
          </Button>
          <FormSubmitBehavior
            onSubmit={(state) => {
              onSubmitForm(state.formValues as Record<string, any>);
            }}
          >
            <Button>Próximo</Button>
          </FormSubmitBehavior>
        </Base>
      </Base>
    </>
  );
};

export default ImagesForm;
