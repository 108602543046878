import Base from "components/basic/base";
import { CardWithSections } from "components/basic/card/card";
import StepsProvider from "components/steps-controller/steps-context";
import Header, { content } from "./header";
import { withAuthenticationRequired } from "react-oidc-context";
import { managePropertyFormContext } from "./context";
import { CardSection } from "components/basic/card/card-section";
import ManagePropertieContents from "./sections";
import { useCrudStore } from "../crud-store";
import { useEffect, useMemo } from "react";
import { getPropertyID } from "utils/getPropertyID";
import { useLayout } from "layout/app/layout-context";

const ManagePropertyForm = () => {
  const { addLoading, removeLoading, isLoading } = useLayout((state) => state);

  const { Provider: ManagePropertyFormContextProvider } =
    managePropertyFormContext;
  const editingEntity = useCrudStore((state) => state.editingEntity);
  const property_id = getPropertyID();

  const initialState = useMemo(() => {
    const currentPropertyWithoutNullAndStringify = editingEntity
      ? Object.fromEntries(
          Object.entries(editingEntity)
            .filter(
              ([_, value]) =>
                value !== null && value !== undefined && value !== ""
            )
            .map(([key, value]) => [key, String(value)])
        )
      : undefined;
    return { values: currentPropertyWithoutNullAndStringify };
  }, [editingEntity]);

  useEffect(() => {
    if (property_id && !editingEntity) addLoading();
    else removeLoading();
  }, [property_id, editingEntity]);

  if (!(property_id && !editingEntity))
    return (
      <Base
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <StepsProvider
          stepIdOrder={Object.keys(content)}
          firstStep={property_id ? "property" : undefined}
        >
          <CardWithSections direction="vertical" sx={{ minWidth: "700px" }}>
            <ManagePropertyFormContextProvider initialState={initialState}>
              <Header />
              <Content />
            </ManagePropertyFormContextProvider>
          </CardWithSections>
        </StepsProvider>
      </Base>
    );
};

export const Content = () => {
  return (
    <CardSection direction="vertical" sx={{ flexGrow: 1, gap: "10px" }}>
      <ManagePropertieContents />
    </CardSection>
  );
};

export default withAuthenticationRequired(ManagePropertyForm);
