import { Blackbox } from "components/basic/blackbox";
import Text from "components/basic/text";
import FormProvider from "components/form/form-store";
import { CardWithSections } from "components/basic/card/card";
import { CardSection } from "components/basic/card/card-section";
import { cssKeyframes } from "styles/animations";
import Icon from "components/basic/icon";
import CardHeader from "components/basic/card/card-header";
import Button from "components/basic/button";
import { BiUserPlus } from "react-icons/bi";
import { FlexRow } from "components/basic/flex";
import TextField from "components/form/custom-fields/text-field";
import DropdownField from "components/form/fields/dropdown-field";
import TextAreaField from "components/form/custom-fields/text-area-field";
import FormSubmitBehavior from "components/form/form-submit";
import Base from "components/basic/base";
import { isValidEmailFormat } from "utils/valide-formats";
import { AddMemberModel } from "services/requests/models/AgencyModel";
import { AgenciesService } from "services/requests";
import { useLayout } from "layout/app/layout-context";
import { globalErrorType, useGlobalError } from "context/error-context";
import { useNavigate } from "react-router";
import { agencyContext } from "pages/authenticated/agency-pages/agencies-context";

const AddUserPopup: Component = ({ visible, setVisible }) => {
  const navigate = useNavigate();
  const updatePopupProps = useLayout((state) => state.updatePopupProps);
  const { setGlobalError } = useGlobalError((state) => state);

  const { useStore: useAgencyStore } = agencyContext;
  const agency_id = useAgencyStore((state) => state.agency.id) ?? "";
  
  if (!visible) return <></>;

  return (
    <Blackbox
      onClick={(event) => {
        event.currentTarget === event.target && setVisible(false);
      }}
    >
      <CardWithSections
        size="small"
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "480px",
          animation: `${cssKeyframes.pop} 0.2s backwards`,
          cursor: "default",
        }}
      >
        <CardHeader sx={{ gap: 1 }}>
          <Icon as={BiUserPlus} sx={{ fontSize: 5 }} />
          <Text
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Adicionar Membro
          </Text>
        </CardHeader>

        <CardSection direction="vertical">
          <FormProvider required={["invited_email", "invited_role"]}>
            <AddUserForm />
          </FormProvider>
        </CardSection>
      </CardWithSections>
    </Blackbox>
  );

  function AddUserForm() {
    return (
      <>
        <FlexRow sx={{ gap: 1 }}>
          <TextField
            name="invited_email"
            label="Email"
            validationMoment="blur"
            validation={({ value }) =>
              isValidEmailFormat(value) ? undefined : "Insira um e-mail válido"
            }
            sx={{ width: "100%" }}
          />
          <DropdownField
            name="invited_role"
            label="Papel"
            options={[
              "Cliente",
              "Corretor",
              "Administrador",
            ]}
            sx={{ width: "122px" }}
          />
        </FlexRow>
        <TextAreaField
          name="message"
          label="Mensagem"
          type="text"
          rows={2}
          sx={{ width: "100%" }}
        />
        <Base
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            gap: 1,
            paddingTop: 2,
          }}
        >
          <Button variant="outlined" onClick={() => setVisible(false)}>
            Cancelar
          </Button>
          <FormSubmitBehavior
            onSubmit={(state) =>
              handlePersist(state.formValues as AddMemberModel)
            }
          >
            <Button>Adicionar</Button>
          </FormSubmitBehavior>
        </Base>
      </>
    );
  }

  async function handlePersist(formValues: AddMemberModel) {
    await AgenciesService.addMember(agency_id, formValues)
      .then((response) => console.log(response))
      .catch((error) =>
        setGlobalError(error as globalErrorType, navigate, updatePopupProps)
      )
      .finally(() => setVisible(false));
  }
};

export default AddUserPopup;
