import {
  FlexCenter,
  FlexColumn,
  FlexFill,
  FlexRow,
} from "components/basic/flex";
import Text from "components/basic/text";
import { CardWithSections } from "components/basic/card/card";
import {
  DGTableContainer,
  DGHeaderColumns,
  DGRows,
} from "components/basic/data-grid";
import { CardSection } from "components/basic/card/card-section";
import { Pagination } from "components/basic/data-fetch/pagination";
import Loading from "components/basic/logos/loading";
import { useCrudStore } from "./crud-store";
import { moneyMask, squareMetersMask } from "utils/input-masks";
import FancyCard from "components/basic/card/fancy-card";
import Icon from "components/basic/icon";
import {
  IconArrowDown,
  IconArrowUp,
  IconCash,
  IconHome,
} from "@tabler/icons-react";
import { ReactNode } from "react";
import { cssKeyframes } from "styles/animations";

type ManagePropertiesTableProps = { numPages: number };
export const ManagePropertiesTable: Component<ManagePropertiesTableProps> = ({
  numPages,
}) => {
  const tableStatistics = useCrudStore((state) => state.tableStatistics);
  const isLoading = useCrudStore((state) => state.isLoading);

  const categoryPercentages = tableStatistics?.category_percentages;
  const average_price_per_m2 = tableStatistics
    ? moneyMask(tableStatistics?.average_price_per_m2.toFixed(2).toString())
        .masked
    : "";
  const average_constructed_area = tableStatistics
    ? squareMetersMask(
        tableStatistics?.average_constructed_area.toFixed(2).toString()
      ).masked
    : "";
  const average_sale_price = tableStatistics
    ? moneyMask(tableStatistics?.average_sale_price.toFixed(2).toString())
        .masked
    : "";
  const min_sale_price = tableStatistics
    ? moneyMask(tableStatistics?.min_sale_price.toFixed(2).toString()).masked
    : "";
  const max_sale_price = tableStatistics
    ? moneyMask(tableStatistics?.max_sale_price.toFixed(2).toString()).masked
    : "";
  const maxCategory = categoryPercentages
    ? Object.keys(categoryPercentages).reduce((max, category) =>
        categoryPercentages[category] > categoryPercentages[max]
          ? category
          : max
      )
    : "";
  const maxPercentage = categoryPercentages
    ? Math.round(categoryPercentages[maxCategory])
    : "";

  return (
    <>
      {tableStatistics && (
        <FlexRow sx={{ gap: 2, flexWrap: "wrap", animation: `${cssKeyframes.slideDown} 0.4s` }}>
          {maxPercentage != 0 && (
            <StatisticsFancyCard
              icon={IconHome}
              title={"Maioria"}
              value={`${maxCategory} (${maxPercentage}%)`}
            />
          )}
          {tableStatistics?.average_price_per_m2 != 0 && (
            <StatisticsFancyCard
              icon={IconCash}
              title={"Preço Médio por m²"}
              value={average_price_per_m2}
            />
          )}
          {tableStatistics?.min_sale_price != 0 && (
            <StatisticsFancyCard
              icon={IconArrowDown}
              title={"Menor Preço"}
              value={min_sale_price}
            />
          )}
          {tableStatistics?.max_sale_price != 0 && (
            <StatisticsFancyCard
              icon={IconArrowUp}
              title={"Maior Preço"}
              value={max_sale_price}
            />
          )}
        </FlexRow>
      )}
      <CardWithSections sx={{ flexGrow: 1, background: "background.paper" }}>
        <CardSection
          addLineSeperator
          sx={{ py: 1, pr: 1, alignItems: "center" }}
        >
          <Text variant="h6">
            Search Results ({tableStatistics?.total_properties})
          </Text>
          <FlexFill />
        </CardSection>

        {/* <Collapse in={isTableVisible}> */}
        <CardSection size="unset" addLineSeperator>
          <DGTableContainer>
            <DGHeaderColumns />
            {isLoading ? <LoadingSection /> : <DGRows />}
          </DGTableContainer>
        </CardSection>

        <CardSection
          variant="light-contrast"
          sx={{ py: "small", justifyContent: "center" }}
        >
          <Pagination numPages={numPages} />
        </CardSection>
      </CardWithSections>
    </>
  );
};

export const LoadingSection: Component = ({}) => {
  return (
    <FlexCenter
      sx={{
        width: "100%",
        background: "neutral.n2",
        p: "huge",
        py: 100,
        gridColumn: "1 / -1",
      }}
    >
      <FlexColumn sx={{ justifyContent: "center", gap: 1 }}>
        <Loading sx={{ height: 100, aspectRatio: "1 / 1", width: "auto" }} />
        <Text variant="h5" sx={{ textAlign: "center", color: "text.faded" }}>
          Carregando...
        </Text>
      </FlexColumn>
    </FlexCenter>
  );
};

export const StatisticsFancyCard = ({
  icon,
  title,
  value,
}: {
  icon?: any;
  title?: string;
  value?: string | ReactNode;
}) => {
  return (
    <FancyCard
      stencilTone="lighten"
      size="nano"
      color="themeBlue.main"
      direction="horizontal"
      contentSx={{ gap: 1, alignItems: "center" }}
      sx={{ color: "white", p: 1 }}
    >
      {icon && (
        <Icon
          as={icon}
          size={30}
          sx={{
            padding: "micro",
            background: "themeBlue.dark1",
            borderRadius: 2,
          }}
        />
      )}
      <FlexColumn>
        <Text variant="h6" sx={{ color: "primary.contrastText" }}>
          {value}
        </Text>
        <Text sx={{ color: "rgba(255,255,255,0.8)" }}>{title}</Text>
      </FlexColumn>
    </FancyCard>
  );
};
